<template>
    <div>
        <div class="text-h6">Успеваемость</div>

        <v-alert v-if="!_.isNil(error)" dense type="error">
            {{ error }}
        </v-alert>
        <v-progress-linear
            v-if="loading.initial"
            :size="50"
            color="purple darken-2"
            indeterminate
        />
        <template v-else>
            <div class="d-flex align-center">
                <v-label>Фильтр</v-label>
                <v-divider vertical class="mx-5"/>
                <v-select
                    v-model="filter.subject"
                    :items="subjects"
                    label="Предмет"
                    color="purple darken-2"
                    class="mr-4"
                    style="max-width: 300px;"
                    @change="onFilterChange"
                />
                <v-select
                    v-model="filter.parallel"
                    :items="parallelsOfGroups"
                    label="Параллель"
                    color="purple darken-2"
                    class="mr-4"
                    style="max-width: 300px;"
                    @change="onFilterChange"
                />

                <v-select
                    v-if="currentTab === 4"
                    v-model="filter.year"
                    :items="years"
                    label="Год"
                    color="purple darken-2"
                    class="mr-4"
                    style="max-width: 300px;"
                />

                <date-picker-field
                    v-else
                    v-model="filter.date"
                    class="mr-4"
                    @input="onDatePickerChange"
                />
                <v-spacer />
                <v-btn
                    :disabled="!allFiltersSelected"
                    :loading="loading.fetch"
                    color="primary"
                    @click="fetch"
                >Отобразить</v-btn>
            </div>

            <div style="height: 10px">
                <v-progress-linear
                    v-if="loading.fetch"
                    :size="50"
                    indeterminate
                />
            </div>

            <v-tabs :value="currentTab" class="mb-5" @change="changeTab">
                <v-tab v-for="n in tabs.length" :key="n">{{ tabs[n - 1] }}</v-tab>
            </v-tabs>
            <template v-if="!loading.fetch">

                <div v-if="onceSearched && results.length" class="d-flex flex-column">
                    
                    <scores-by-subject-page
                        v-if="currentTab === 0" :results="results"
                        :prev-results="prevResults"
                        :export-file-name="`Статистика по предметам ${filter.subject} ${selectedParallelText} ${getHumanDate(filter.date[0])} - ${getHumanDate(filter.date[1])}`"
                    />
                    <scores-by-group-page
                        v-if="currentTab === 1"
                        :results="results"
                        :prev-results="prevResults"
                        :export-file-name="`Статистика по классу ${filter.subject} ${selectedParallelText} ${getHumanDate(filter.date[0])} - ${getHumanDate(filter.date[1])}`"
                    />
                    <scores-by-subject-by-theme-page
                        v-if="currentTab === 2"
                        :results="results"
                        :prev-results="prevResults"
                        :export-file-name="`Сводная по темам ${filter.subject} ${selectedParallelText} ${getHumanDate(filter.date[0])} - ${getHumanDate(filter.date[1])}`"
                    />
                    <scores-by-group-by-theme-page
                        v-if="currentTab === 3"
                        :results="results"
                        :prev-results="prevResults"
                        :export-file-name="`Сводная по темам в классе ${filter.subject} ${selectedParallelText} ${getHumanDate(filter.date[0])} - ${getHumanDate(filter.date[1])}`"
                    />
                    <scores-by-group-by-collection-page
                        v-if="currentTab === 4"
                        :results="allFiltersSelected ? results : []"
                        :export-file-name="`Сводная ${filter.subject} ${selectedParallelText} ${filter.year}`"
                    />
                </div>
                <p v-else>Укажите параметры фильтра и нажмине "Отобразить"</p>
            </template>
        </template>
    </div>
</template>

<script>
import ScoresBySubjectPage from '@/components/performance/ScoresBySubjectPage.vue'
import ScoresByGroupPage from '@/components/performance/ScoresByGroupPage.vue'
import ScoresBySubjectByThemePage from '@/components/performance/ScoresBySubjectByThemePage.vue'
import ScoresByGroupByThemePage from '@/components/performance/ScoresByGroupByThemePage.vue'
import ScoresByGroupByCollectionPage from '@/components/performance/ScoresByGroupByCollectionPage.vue'
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'
import DatePickerField from '@/components/ui/DatePickerField.vue'

const maxRate = 5
const tabMethodsNeededPrevValue = [
    'retrieveResultsBySubject',
    'retrieveResultsByTheme'
]
const tabToMethod = [
                'retrieveResultsBySubject',
                'retrieveResultsBySubject',
                'retrieveResultsByTheme',
                'retrieveResultsByTheme',
                'retrieveResultsByJobs'
            ]
const tabs = [
                'Статистика по предметам',
                'Статистика по классу',
                'Статистика по темам по классам',
                'Статистика по темам в классе',
                'Сводная по классам'
            ]

export default {
    components: {
        ScoresBySubjectPage, ScoresByGroupPage,
        ScoresBySubjectByThemePage, ScoresByGroupByThemePage,
        ScoresByGroupByCollectionPage, DatePickerField
    },
    mixins: [retrieveOrLoadMixin],
    data () {
        return {
            onceSearched: false,
            error: null,
            students: [],
            loading: {
                initial: false,
                fetch: false
            },
            dialogs: {
                date: false
            },
            filter: {
                date: [],
                started_at: null,
                ended_at: null,
                subject: null,
                parallel: null,
                year: null
            },
            results: [],
            prevResults: [],
            currentTab: 0
        }
    },
    async created () {
        if (this.$store.state.performance.filter) {
            this.filter = _.cloneDeep(this.$store.state.performance.filter)
        }
        this.loading.initial = true
        await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        
        await Promise.all([
            this.retrieveOrLoad({ module: 'group', action: 'fetch', fields: 'grade,name,student_id' }),
            this.fetchStudents()
        ])
        
        this.loading.initial = false
    },
    computed: {
        allFiltersSelected () {
            const requiredFields = this.currentTab === 4 ?
                                    ['subject', 'parallel', 'year'] :
                                    ['subject', 'parallel','started_at', 'ended_at']
            return requiredFields.every(key => !!this.filter[key])
        },
        years () {
            const years = []
            const minYear = 2023
            const curMonth = (new Date()).getMonth() + 1
            let currentYear = (new Date()).getFullYear()
   
            if (curMonth < 9)
                currentYear--
            
            for (let year = currentYear; year >= minYear; year--) {
                years.push(year)
            }
            return years
        },
        subjects () {
            return [
                {
                    text: 'Все предметы',
                    value: 'Все предметы'
                },
                ...this.$store.state.app.subjects
            ]
        },
        tabs () {
            return this.filter.subject === 'Все предметы' ? tabs.slice(0, 2) : tabs
        },
        parallelsOfGroups () {
            const groupedByGrade = _.groupBy(this.groups, g => g.grade)
            return [
                {
                    text: 'Все параллели',
                    value: this.groups.map(g => g.value)
                },
                ...Object.keys(groupedByGrade).map(parallel => ({
                    text: `${parallel}я параллель`,
                    value: this.groups.filter(g => g.grade === parseInt(parallel)).map(g => g.value)
                }))
            ]
        },
        selectedParallelText () {
            return this.parallelsOfGroups.find(item => item.value.join(',') === this.filter.parallel?.join(','))?.text
        },
        groups () {
            return this.$store.state.group.items?.map(item => ({
                                                                    text: item.name,
                                                                    value: item.id,
                                                                    grade: item.grade,
                                                                    student_id: item.student_id
                                                                }))
                                                .sort((a, b) => a.text.localeCompare(b.text)) || []
        }
    },
    methods: {
        getHumanDate (timestamp) {
            return this.$moment(timestamp, 'X').format('YYYY-MM-DD')
        },
        async fetchStudents () {
            try {
                const { success, error, data } = await this.$store.dispatch('user/list', {
                            pagination: 0,
                            fields: 'id,name',
                            filter: { role: 'student' }
                        })
                
                if (!success)
                    throw new Error(error)

                this.students = data.items?.map(item => ({
                    text: item.name,
                    value: item.id
                }))
                return true
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
                return false
            }
        },
        async retrieveResultsBySubject (filter) {

            const requestFilter = {
                status: 'past',
                checked: 1,
                started_at: { '>=': this.$moment(filter.started_at, 'X').startOf('day').format('X') },
                ended_at: { '<=': this.$moment(filter.ended_at, 'X').endOf('day').format('X') },
                group_id: { in: filter.parallel }
            }
            if (filter.subject !== 'Все предметы')
                requestFilter.subject = filter.subject
            
            const { success: s1, error: e1, data: d1 } = await this.$store.dispatch('assigned_job/list', {
                    fields: ['group_id'].join(','),
                    filter: requestFilter,
                    pagination: 0
                })
            if (!s1)
                throw new Error(e1)

            const assigned_job_ids = d1.items.map(item => item.id)

            const { success: s2, error: e2, data: d2 } = await this.$store.dispatch('result/list', {
                fields: ['all_parts_score_percent', 'student_id', 'assigned_job_id'].join(','),
                filter: {
                    assigned_job_id: { in: assigned_job_ids }
                },
                pagination: 0
            })

            if (!s2)
                throw new Error(e2)

            const results = []
            // Группируем по ученикам
            d2.items.forEach(item => {
                const group_id = d1.items.find(assigned_job => assigned_job.id === item.assigned_job_id)?.group_id
                let groupChunk = results.find(r => r.group_id === group_id)
                
                if (!groupChunk) {
                    const group = this.groups.find(g => g.value === group_id)

                    groupChunk = {
                        group_id,
                        name: group?.text || 'Неизвестно',
                        grade: group?.grade || 'Неизвестно',
                        items: []
                    }
                    results.push(groupChunk)
                }

                let student = groupChunk.items.find(student => student.student_id === item.student_id)

                if (!student) {
                    student = {
                        student_id: item.student_id,
                        name: this.students.find(s => s.value === item.student_id)?.text,
                        scores: []
                    }
                    groupChunk.items.push(student)
                }

                student.scores.push(this.getRate(item.all_parts_score_percent))
            })

            // Add groups with empty values
            const addedGroupsToResult = results.map(g => g.group_id)
            const missedGroups = this.groups.filter(group => {
                return this.filter.parallel.includes(group.value) && !addedGroupsToResult.includes(group.value)
            })
            missedGroups.forEach(g => {
                results.push({
                        group_id: g.value,
                        name: g?.text || 'Неизвестно',
                        grade: g?.grade || 'Неизвестно',
                        items: []
                    })
            })
            // Add user info that not present in report
            this.results.forEach(groupChunk => {
                const group = this.groups.find(g => g.value === groupChunk.group_id)
                if (!group) { return }
                
                const studentIdsWithResult = groupChunk.items.map(s => s.student_id)
                const missedStudentResults = this.students
                                                        .filter(s => group.student_id.includes(s.value) && !studentIdsWithResult.includes(s.value))
                                                        .map(s => ({
                                                            student_id: s.value,
                                                            name: s.text,
                                                            scores: []
                                                        }))
                groupChunk.items.push(...missedStudentResults)
            })
            // Sort by grade
            return results.sort((a, b) => a.grade - b.grade)
        },
        async retrieveResultsByTheme (filter) {
            const { success: s1, error: e1, data: d1 } = await this.$store.dispatch('assigned_job/list', {
                    fields: ['group_id'].join(','),
                    filter: {
                        status: 'past',
                        checked: 1,
                        started_at: { '>=': this.$moment(filter.started_at, 'X').startOf('day').format('X') },
                        ended_at: { '<=': this.$moment(filter.ended_at, 'X').startOf('day').format('X') },
                        subject: filter.subject,
                        group_id: { in: filter.parallel }
                    },
                    pagination: 0
                })
            if (!s1)
                throw new Error(e1)

            const assigned_job_ids = d1.items.map(item => item.id)

            const { success: s2, error: e2, data: result_of_tasks } = await this.$store.dispatch('result_of_task/list', {
                fields: ['task_id', 'score_percent', 'student_id', 'assigned_job_id'].join(','),
                filter: {
                    assigned_job_id: { in: assigned_job_ids }
                },
                pagination: 0
            })

            if (!s2)
                throw new Error(e2)

            const uniqueTaskIds = Array.from(new Set(result_of_tasks.items.map(item => item.task_id)))
            const { success: s3, error: e3, data: themes } = await this.$store.dispatch('theme_of_task/list', {
                fields: ['task_id', 'theme'].join(','),
                filter: {
                    task_id: { in: uniqueTaskIds }
                },
                pagination: 0
            })

            if (!s3)
                throw new Error(e3)

            const themesToTaskIds = []

            uniqueTaskIds.forEach(task_id => {
                const themeObject = themes.items.find(item => item.task_id === task_id)

                if (!themeObject) { return }
                let ttti = themesToTaskIds.find(item => item.theme === themeObject.theme)

                if (!ttti) {
                    ttti = { theme: themeObject.theme, task_id: [] }
                    themesToTaskIds.push(ttti)
                }

                ttti.task_id.push(task_id)
            })

            const results = []
            // Группируем по ученикам
            result_of_tasks.items.forEach(item => {
                const group_id = d1.items.find(assigned_job => assigned_job.id === item.assigned_job_id)?.group_id
                let groupChunk = results.find(r => r.group_id === group_id)
                
                if (!groupChunk) {
                    const group = this.groups.find(g => g.value === group_id)

                    groupChunk = {
                        group_id,
                        name: group?.text || 'Неизвестно',
                        grade: group?.grade || 'Неизвестно',
                        items: []
                    }
                    results.push(groupChunk)
                }

                let student = groupChunk.items.find(student => student.student_id === item.student_id)

                if (!student) {
                    student = {
                        student_id: item.student_id,
                        name: this.students.find(s => s.value === item.student_id)?.text,
                        scores: []
                    }
                    groupChunk.items.push(student)
                }
                const theme = themesToTaskIds.find(_item => _item.task_id.includes(item.task_id))?.theme
                if (!theme) { return }
                
                let themeScores = student.scores.find(themeScores => themeScores.theme === theme)

                if (!themeScores) {
                    themeScores = { theme, scores: [] }
                    student.scores.push(themeScores)
                }
                themeScores.scores.push(item.score_percent)
            })
            // Add groups with empty values
            const addedGroupsToResult = results.map(g => g.group_id)
            const missedGroups = this.groups.filter(group => {
                return this.filter.parallel.includes(group.value) && !addedGroupsToResult.includes(group.value)
            })
            missedGroups.forEach(g => {
                results.push({
                        group_id: g.value,
                        name: g?.text || 'Неизвестно',
                        grade: g?.grade || 'Неизвестно',
                        items: []
                    })
            })
            // Add user info that not present in report
            results.forEach(groupChunk => {
                const group = this.groups.find(g => g.value === groupChunk.group_id)
                if (!group) { return }
                
                const studentIdsWithResult = groupChunk.items.map(s => s.student_id)
                const missedStudentResults = this.students
                                                        .filter(s => group.student_id.includes(s.value) && !studentIdsWithResult.includes(s.value))
                                                        .map(s => ({
                                                            student_id: s.value,
                                                            name: s.text,
                                                            scores: []
                                                        }))
                groupChunk.items.push(...missedStudentResults)
            })
            // Sort by grade
            return results.sort((a, b) => a.grade - b.grade)
        },
        async retrieveResultsByJobs (filter) {
            
            const startMonth = 9
            const endMonth = 8
            let startYear = filter.year
            let endYear = filter.year + 1
            const startDate = this.$moment(`${startYear}-${startMonth}`).startOf('month')
            const endDate = this.$moment(`${endYear}-${endMonth}`).endOf('month')
            const dateFilter = { started_at: startDate.format('X'), ended_at: endDate.format('X') }
 
            const { success: s1, error: e1, data: d1 } = await this.$store.dispatch('assigned_job/list', {
                    fields: ['group_id', 'collection'].join(','),
                    filter: {
                        status: 'past',
                        checked: 1,
                        started_at: { '>=': dateFilter.started_at },
                        ended_at: { '<=': dateFilter.ended_at },
                        subject: filter.subject,
                        group_id: { in: filter.parallel },
                        category: 'control'
                    },
                    pagination: 0
                })
            if (!s1)
                throw new Error(e1)

            const { success: s2, error: e2, data: d2 } = await this.$store.dispatch('result/list', {
                fields: ['all_parts_score_percent', 'student_id', 'assigned_job_id', 'collection'].join(','),
                filter: {
                    assigned_job_id: { in: d1.items.map(item => item.id) }
                },
                pagination: 0
            })

            if (!s2)
                throw new Error(e2)

            const results = []

            // Группируем по ученикам
            d2.items.forEach(item => {
                const assigned_job = d1.items.find(assigned_job => assigned_job.id === item.assigned_job_id)
                const group_id = assigned_job?.group_id
                const collection = assigned_job?.collection
                let groupChunk = results.find(r => r.group_id === group_id)
                
                if (!groupChunk) {
                    const group = this.groups.find(g => g.value === group_id)

                    groupChunk = {
                        group_id,
                        name: group?.text || 'Неизвестно',
                        grade: group?.grade || 'Неизвестно',
                        items: [],
                        collections: []
                    }
                    results.push(groupChunk)
                }
                if (!groupChunk.collections.includes(collection))
                    groupChunk.collections.push(collection)

                let student = groupChunk.items.find(student => student.student_id === item.student_id)

                if (!student) {
                    student = {
                        student_id: item.student_id,
                        name: this.students.find(s => s.value === item.student_id)?.text
                    }
                    groupChunk.items.push(student)
                }
                student[`job_collection_${collection}`] = this.getRate(item.all_parts_score_percent)
            })
            // Add groups with empty values
            const addedGroupsToResult = results.map(g => g.group_id)
            const missedGroups = this.groups.filter(group => {
                return this.filter.parallel.includes(group.value) && !addedGroupsToResult.includes(group.value)
            })
            missedGroups.forEach(g => {
                results.push({
                        group_id: g.value,
                        name: g?.text || 'Неизвестно',
                        grade: g?.grade || 'Неизвестно',
                        items: []
                    })
            })
            // Add user info that not present in report
            results.forEach(groupChunk => {
                const group = this.groups.find(g => g.value === groupChunk.group_id)
                if (!group) { return }
                
                const studentIdsWithResult = groupChunk.items.map(s => s.student_id)
                const missedStudentResults = this.students
                                                        .filter(s => group.student_id.includes(s.value) && !studentIdsWithResult.includes(s.value))
                                                        .map(s => ({
                                                            student_id: s.value,
                                                            name: s.text,
                                                            scores: []
                                                        }))
                groupChunk.items.push(...missedStudentResults)
            })
            // Sort by grade
            return results.sort((a, b) => a.grade - b.grade)
        },
        async fetch () {
            this.results = []
            this.prevResults = []
            this.error = null
            
            if (!this.allFiltersSelected) { return }
            this.onceSearched = true
            this.loading.fetch = true
            
            try {
                this.results = await this[tabToMethod[this.currentTab]](this.filter)
                if (tabMethodsNeededPrevValue.includes(tabToMethod[this.currentTab]))
                    this.prevResults = await this[tabToMethod[this.currentTab]](this.getPrevMonthFilter())
              
            } catch (e) {
                console.error(e)
                this.error = e?.message || 'Неизвестная ошибка при запросе на сервер'
            } finally {
                this.loading.fetch = false
            }
        },
        getRate (score_percent) {
            return Math.round((maxRate / 100) * score_percent)
        },
        onDatePickerChange (payload) {
            const [ started_at, ended_at ] = payload
            this.filter.started_at = started_at
            this.filter.ended_at = ended_at
            this.onFilterChange()
        },
        onFilterChange () {
            this.$store.dispatch('performance/setFilter', this.filter)
            
            if (!this.filter.subject)
                this.changeTab(0)
        },
        getPrevMonthFilter () {
            const prevMonthFilter = {...this.filter}
          
            const diffInSeconds = prevMonthFilter.date[1] - prevMonthFilter.date[0]
            const endedAtDate = this.$moment(prevMonthFilter.date[0], 'X').subtract(1, 'days').endOf('day')
            const startAtDate = this.$moment(prevMonthFilter.date[0], 'X').subtract(diffInSeconds, 'seconds').subtract(1, 'days').startOf('day')
            prevMonthFilter.started_at = startAtDate.format('X')
            prevMonthFilter.ended_at = endedAtDate.format('X')
            return prevMonthFilter
        },
        resetDate () {
            this.filter.date = null
            this.filter.started_at = null
            this.filter.ended_at = null
            this.dialogs.date = false
            this.onFilterChange()
        },
        changeTab (value) {
            let willFetch = false
            
            if (tabToMethod[value] !== tabToMethod[this.currentTab])
                willFetch = true

            this.currentTab = value
            
            if (willFetch)
                this.fetch()
        }
    }
}
</script>